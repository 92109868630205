$(document).ready(function() {
    var promotionDropdown = $('#user_subscription_promotion_id');
    var subscriptionDropdown = $('#user_subscription_subscription_id');

    promotionDropdown.select2({
        placeholder: 'Select a Promotion',
        allowClear: true
    });

    promotionDropdown.empty().append('<option value="">Select a Promotion</option>');

    function fetchPromotions(subscriptionId) {
        promotionDropdown.empty().append('<option value="">Select a Promotion</option>');
        if (subscriptionId) {
            $.ajax({
                url: '/portal/user_subscriptions/fetch_promotions',
                type: 'GET',
                data: { subscription_id: subscriptionId },
                success: function(data) {
                    $.each(data.promotions, function(index, promotion) {
                        promotionDropdown.append('<option value="' + promotion[1] + '">' + promotion[0] + '</option>');
                    });
                    promotionDropdown.select2({
                        placeholder: 'Select a Promotion',
                        allowClear: true
                    });
                },
                error: function() {
                    console.log('Error fetching promotions');
                }
            });
        }
    }

    subscriptionDropdown.on('change', function() {
        var subscriptionId = $(this).val();
        fetchPromotions(subscriptionId);
    });

    var preselectedSubscriptionId = subscriptionDropdown.val();
    if (preselectedSubscriptionId) {
        fetchPromotions(preselectedSubscriptionId);
    }
});