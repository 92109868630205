import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["from", "to", "checkbox", "exceptionsContainer", "template"]

  toggleAlwaysAvailable(event) {
    const checked = event.target.checked

    this.fromTarget.disabled = checked
    this.toTarget.disabled = checked

    if (checked) {
      this.fromTarget.value = ""
      this.toTarget.value = ""
    }
  }

  copyToAllDays(event) {
    const section = event.target.closest('section')

    const from = this.fromTarget.value
    const to = this.toTarget.value
    const checkbox = this.checkboxTarget.checked

    section.querySelectorAll(".nested-fields").forEach((element) => {
      const fromField = element.querySelector('input[name*="[from]"]')
      const toField = element.querySelector('input[name*="[to]"]')
      const checkboxField = element.querySelector('input[type="checkbox"][name*="[always_available]"]')
      const hiddenField = element.querySelector('input[type="hidden"][name*="[always_available]"]')

      if (fromField && toField && checkboxField && hiddenField) {
        fromField.value = from
        toField.value = to
        checkboxField.checked = checkbox

        hiddenField.value = checkbox ? "1" : "0"

        fromField.disabled = checkbox
        toField.disabled = checkbox
      } else {
        console.warn("One or more fields are missing in this element:", element)
      }
    })
}

  addException(event) {
    console.log("addException")
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
    this.exceptionsContainerTarget.insertAdjacentHTML('beforeend', content)
  }

}