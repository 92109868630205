import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "country", "mobile", "mobileNumberLength" ]

  initialize() {
    console.log("stimulus in actions :) ")
    this.setMobileNumberLengthByCountry();
  }

  setMobileNumberLengthByCountry() {
    if (!this.countryTarget.value) return;
    this.mobileTarget.maxLength = this.countryTarget.options[this.countryTarget.options.selectedIndex].dataset["numberLength"];
  }
}
