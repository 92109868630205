import React from 'react';
import ReactDOM from 'react-dom'
import {MomentDate} from './Components/MomentDate';
document.addEventListener("DOMContentLoaded", () => loadMomentDate());

function loadMomentDate() {
  const dateTimes = document.querySelectorAll('[data-selector="datetime"]');
  for (let dateTime of dateTimes) {
    ReactDOM.render(
      <MomentDate
        date={dateTime.dataset.date}
        locale={dateTime.dataset.locale}
      />, dateTime)
  }
}
